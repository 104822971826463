<template>
<div>
        <v-container>
            <v-layout row wrap >
                <v-flex xs12 text-xs-center>
                    <h1>TEMA 3 // PLANIFICA TU FUTURO</h1>
                </v-flex>
            </v-layout>
            </v-container>
        <v-container>
        <v-layout >
            <v-flex xs12 my-3 text-xs-center>
                <h2 class="text-uppercase">¿QUIERES PLANIFICAR TU NEGOCIO A LARGO PLAZO? </h2>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs6 offset-xs3 my-3 text-xs-center>
                <h5 class="">Realiza estas actividades para aprender más sobre tus finanzas y acumula puntos que podrás canjear en beneficios en RedCapital. Todos tus documentos son privados. Lee más sobre las Membresías <a href="#">aquí</a>
                </h5>
            </v-flex>
        </v-layout>
        <v-layout class="estadosmembresias" row >
            <v-flex xs5 offset-xs1 md4 offset-md2 text-xs-center class="pa-3">
                <v-card text-xs-center class="fondopendientes positionrelative centrar text-center pt-3">
                    <v-img
                        src="/images/actividad/actividades/metas.png"
                        width="100px"
                        class="positionabsolute"
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-metas.png"
                        width="100px"
                        ></v-img>
                    <v-card-title primary-title text-xs-center class="fullwidth">
                        <div class="fullwidth">
                            <h3 class="headline mb-0 text-uppercase">METAS A<br> CORTO PLAZO</h3>
                        </div>
                    </v-card-title>
                    <v-card-title class="pb-5">
                        <h5>Detalla los objetivos para el próximo mes.</h5>
                    </v-card-title>
                    <v-flex class="fondopuntospendientes pa-3" width="100%">
                            <div>
                                <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                            </div>
                        <v-progress-linear
                            color="fondopendientes"
                            height="25"
                            value="75"
                            class="progressradius"
                        >+2</v-progress-linear>
                    </v-flex>
                </v-card>
            </v-flex>
            <v-flex xs5 md4 class="pa-3">
                <v-card text-xs-center class="fondopendientes positionrelative centrar text-center pt-3">
                    <v-img
                        src="/images/actividad/actividades/metas.png"
                        width="100px"
                        class="positionabsolute"
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-metas-largo-plazo.png"
                        width="100px"
                        ></v-img>
                    <v-card-title primary-title text-xs-center class="fullwidth">
                        <div class="fullwidth">
                            <h3 class="headline mb-0 text-uppercase">METAS A<br> LARGO PLAZO</h3>
                        </div>
                    </v-card-title>
                    <v-card-title class="pb-5">
                        <h5>Detalla los objetivos para el año.</h5>
                    </v-card-title>
                    <v-flex class="fondopuntospendientes pa-3" width="100%">
                            <div>
                                <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                            </div>
                        <v-progress-linear
                            color="fondopendientes"
                            height="25"
                            value="75"
                            class="progressradius"
                        >+2</v-progress-linear>
                    </v-flex>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs5 offset-xs1 md4 offset-md2 class="pa-3 ">
                <v-card text-xs-center class="fondopendientes positionrelative centrar text-center pt-3">
                    <v-img
                        src="/images/actividad/actividades/fotos.png"
                        width="100px"
                        class="positionabsolute"
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-recordatorio.png"
                        width="100px"
                        ></v-img>
                    <v-card-title primary-title text-xs-center class="fullwidth">
                        <div class="fullwidth">
                            <h3 class="headline mb-0 text-uppercase">RECORDATORIO DE<br> OBJETIVOS</h3>
                        </div>
                    </v-card-title>
                    <v-card-title class="pb-5">
                        <h5>Toma una foto y súbela para mostrarnos de qué manera recuerdas tus objetivos comerciales.</h5>
                    </v-card-title>
                    <v-flex class="fondopuntospendientes pa-3" width="100%">
                            <div>
                                <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                            </div>
                        <v-progress-linear
                            color="fondopendientes"
                            height="25"
                            value="75"
                            class="progressradius"
                        >+2</v-progress-linear>
                    </v-flex>
                </v-card>
            </v-flex>
            <v-flex xs5 md4 class="pa-3 ">
                <v-card text-xs-center class="fondopendientes positionrelative centrar text-center pt-3">
                     <v-img
                        src="/images/actividad/actividades/objetivo.png"
                        width="100px"
                        class="positionabsolute"
                        ></v-img>
                    <v-img
                        src="/images/actividad/icono-objetivo.png"
                        width="100px"
                        ></v-img>
                    <v-card-title primary-title text-xs-center class="fullwidth">
                        <div class="fullwidth">
                            <h3 class="headline mb-0 text-uppercase">OBJETIVOS DE INVERSIÓN<br> DEL NEGOCIO</h3>
                        </div>
                    </v-card-title>
                    <v-card-title class="pb-5">
                        <h5>Define objetivos de ahorro e inversión <br>para tu negocio.</h5>
                    </v-card-title>
                    <v-flex class="fondopuntospendientes pa-3" width="100%">
                            <div>
                                <h5 class=" mb-0 text-uppercase colorblanco">puntos por actividad</h5>
                            </div>
                        <v-progress-linear
                            color="fondopendientes"
                            height="25"
                            value="75"
                            class="progressradius"
                        >+2</v-progress-linear>
                    </v-flex>
                </v-card>
            </v-flex>
        </v-layout>
         
     
 </v-container>
</div>
</template>

<script>
 import { Component, Vue } from "vue-property-decorator";
@Component({
    data() {
      return {
            e1: 1
        }
    }
})
export default class AdministracionFuturo extends Vue {}
</script>

<style>
.fullwidth{
    width: 100%;
}
.headline {
    border-bottom: 1px solid #fff !important;
    padding-bottom: 5px;
}
.positionabsolute{
    position: absolute;
    top: 0;
    right: 0;
}
.positionrelative{
    position: relative;
}
.progressradius{
    border-radius: 15px;
    color: white;
    font-weight: 600;
    font-size: 1em;
}
.text-center{
    text-align: center;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.centrar{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.circleetapa1::before {
    content: "1";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #24AE94;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress1{
     background: #B8EAE0;
}
.circleetapa2::before {
    content: "2";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #BE75F7;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress2{
     background: #BE75F7;
}
.circleetapa3::before {
    content: "3";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #FCAF22;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress3{
     background: #FCAF22;
}
.circleetapa1, .circleetapa2, .circleetapa3 {
    position: relative;
}
.circleetapa1 h3 {
    color: #24AE94;
}
.circleetapa2 h3 {
    color: #BE75F7;
}
.circleetapa3 h3 {
    color: #FCAF22;
}
.colornegro{
    color: #000 !important;
}
.colorblanco{
    color: #fff;
}
.colorcomienza{
    color: #24AE94;
}
.colorcrece{
    color: #BE75F7;
}
.colorexpande{
    color:#FCAF22;
}
.fondogris{
    background: #F4F4F4;
    border-radius: 10px;
}
.fondodisabled{
    background-color: #B8F6EA !important;
}
.fondopuntos{
    background: #BAD8D2;
    width: 100%;
}
.btn-color{
    background:#92AFAF !important;
}
.btn-colorpendientes{
      background:#D36523 !important;
}
.fondopendientes{
    background: #B88AF7 !important;
}
.fondopuntospendientes{
    background:#4F2981;
     width: 100%;
}
.colorbeneficios{
    color: #3EAFD2;
}
.colorbeneficiosdisabled{
    color: #CCC7C7;
}
</style>